export enum PERMISSIONS {
  SYNCING_NOTIFICATION = "SYNCING_NOTIFICATION",
  ADMIN_MANAGEMENT_READ = "ADMIN_MANAGEMENT_READ",
  ADMIN_MANAGEMENT_EDIT = "ADMIN_MANAGEMENT_EDIT",
  PERMISSION_MANAGEMENT_READ = "PERMISSION_MANAGEMENT_READ",
  PERMISSION_MANAGEMENT_EDIT = "PERMISSION_MANAGEMENT_EDIT",
  REPORT_MANAGEMENT_READ = "REPORT_MANAGEMENT_READ",
  REPORT_MANAGEMENT_EDIT = "REPORT_MANAGEMENT_EDIT",
  EMPLOYEE_MANAGEMENT_READ = "EMPLOYEE_MANAGEMENT_READ",
  BUDGET_MANAGEMENT_READ = "BUDGET_MANAGEMENT_READ",
  REQUEST_MANAGEMENT_READ = "REQUEST_MANAGEMENT_READ",
  SETTING_MANAGEMENT_READ = "SETTING_MANAGEMENT_READ",
  SETTING_MANAGEMENT_EDIT = "SETTING_MANAGEMENT_EDIT",
  PAYMENT_CYCLE_READ = "PAYMENT_CYCLE_READ",
  PAYMENT_CYCLE_EDIT = "PAYMENT_CYCLE_EDIT",
  PAYMENT_CYCLE_ACTIVATION = "PAYMENT_CYCLE_ACTIVATION ",
}

export enum EXPORT_REPORT_PERMISSIONS {
  EXPORT_ALL_REPORT = "EXPORT_ALL_REPORT",
  MEDICAL_REIMBURSEMENT_PER_EMPLOYEE = "MEDICAL_REIMBURSEMENT_PER_EMPLOYEE",
  MEDICAL_REIMBURSEMENT_DEPARTMENT = "MEDICAL_REIMBURSEMENT_DEPARTMENT",
  SUPPORT_CHILD_PER_EMPLOYEE = "SUPPORT_CHILD_PER_EMPLOYEE",
  SUPPORT_CHILD_UNDER_18_PER_EMPLOYEE = "SUPPORT_CHILD_UNDER_18_PER_EMPLOYEE",
  SUPPORT_CHILD_HISTORY_DISBURSEMENT = "SUPPORT_CHILD_HISTORY_DISBURSEMENT",
  EDUCATION_CHILD_PER_EMPLOYEE = "EDUCATION_CHILD_PER_EMPLOYEE",
  COMPENSATION_FUNERAL_DISBURSEMENT_SUPPORT = "COMPENSATION_FUNERAL_DISBURSEMENT_SUPPORT",
  COMPENSATION_FUNERAL_DISBURSEMENT = "COMPENSATION_FUNERAL_DISBURSEMENT",
  COMPENSATION_FUNERAL_DISBURSEMENT_EQUIPMENT = "COMPENSATION_FUNERAL_DISBURSEMENT_EQUIPMENT",
  SUMMARY_MEDICAL_REIMBURSEMENT_DEPARTMENT = "SUMMARY_MEDICAL_REIMBURSEMENT_DEPARTMENT",
  SUMMARY_CHILD_EDUCATION_REIMBURSEMENT_DEPARTMENT = "SUMMARY_CHILD_EDUCATION_REIMBURSEMENT_DEPARTMENT",
  SUMMARY_CHILD_SUPPORT_REIMBURSEMENT_DEPARTMENT = "SUMMARY_CHILD_SUPPORT_REIMBURSEMENT_DEPARTMENT",
  SUMMARY_ALL_EACH_TIMES = "SUMMARY_ALL_EACH_TIMES",
  AFFECT_CHILD_SUPPORT_INCREASE = "AFFECT_CHILD_SUPPORT_INCREASE",
  AFFECT_CHILD_SUPPORT_REDUCE = "AFFECT_CHILD_SUPPORT_REDUCE",
}
