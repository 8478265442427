// import { useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Breadcrumbs,
  BrowseButton,
  Button,
  Divider,
  Helmet,
  Link,
  Paper,
  Typography,
} from "components";
import Authorize from "components/Authorize";
import { compose, defaultProps, withHooks } from "enhancers";
import { AppColor } from "theme/app-color";
import { v4 as uuidv4 } from "uuid";
const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: AppColor["Primary/Primary"],
    borderColor: AppColor["Primary/Primary"],
    color: AppColor["White / White"],
    "&:hover": {
      backgroundColor: AppColor["Primary/Primary"],
      borderColor: AppColor["Primary/Primary"],
      color: AppColor["White / White"],
    },
  },
}));

const PageContent = (props: any) => (
  <Box minWidth={786} width={props.pageWidth} mx="auto">
    <Helmet title={props.title} />
    <Box display="flex">
      <Box flex={1}>
        <Typography variant="h2">{props.title}</Typography>
      </Box>
      {props.pageActions.map((actionProps: any, index: any) => (
        <Authorize
          key={"pageActions_" + uuidv4()}
          permissions={actionProps.permittedRoles}
        >
          {actionProps.type !== "file" && (
            <Button
              key={"action_Button_" + uuidv4()}
              ml={4}
              {...actionProps}
              // className={props.classes.button}
            />
          )}
          {actionProps.type === "file" && (
            <BrowseButton
              key={"action_BrowseButton_" + uuidv4()}
              ml={4}
              {...actionProps}
            />
          )}
        </Authorize>
      ))}
    </Box>
    {props.breadcrumbs ? (
      <>
        <Breadcrumbs aria-label="breadcrumb" mt={2}>
          {props.breadcrumbs.map(({ label, path }: any, index: any) => (
            <Link
              key={label + "_" + path}
              color={path ? AppColor["Primary/Primary"] : "inherit"}
              to={path}
            >
              {label}
            </Link>
          ))}
        </Breadcrumbs>
        <Divider mt={6} />
        {props.banner}
      </>
    ) : (
      <>
        <Divider mt={13} />
        {props.banner}
      </>
    )}
    {props.topContentComponent}
    {props.filter && (
      <Paper mt={6} p={4}>
        {props.filter}
      </Paper>
    )}
    {props.paper ? props.paperComponent : props.children}
  </Box>
);

const enhancer = compose(
  defaultProps({
    paper: true,
  }),
  withHooks((props: any, hooks: any) => {
    const {
      title,
      breadcrumbs,
      pageActions = [],
      children,
      paper,
      banner,
      noPadding,
      filter,
      topContent,
      widthAuto,
    } = props;
    const { useMemo } = hooks;
    // const isMobileSize = useMediaQuery("(max-width: 786px)");
    const classes = useStyles();

    const paperComponent = useMemo(() => {
      if (noPadding) {
        return <Paper mt={6}>{children}</Paper>;
      } else {
        return (
          <Paper mt={6} px={4} py={6}>
            {children}
          </Paper>
        );
      }
    }, [children, noPadding]);

    const topContentComponent = useMemo(() => {
      if (topContent) return topContent;
      return <></>;
    }, [topContent]);

    const pageWidth = useMemo(() => {
      if (widthAuto) return "auto";
      return 1080;
    }, [widthAuto]);

    return {
      title,
      breadcrumbs,
      pageActions,
      children,
      paper,
      // isMobileSize,
      banner,
      classes,
      noPadding,
      filter,
      paperComponent,
      pageWidth,
      topContentComponent,
    };
  })
);

export default enhancer(PageContent);

export interface BreadcrumbsProps {
  path: string | null;
  label: string;
}
