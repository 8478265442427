import { Box, Modal, Table, Typography } from "components";
import dayjs from "dayjs";
import { compose, withAuthorize, withHooks, withTranslation } from "enhancers";
import { useCallback, useEffect, useMemo } from "react";
import { AppColor } from "theme/app-color";
import { gql, paths } from "utils/helper";
import { waiting_requests_id } from "../PaymentCycleTable";
import PaymentCycleSwitcher from "./PaymentCycleSwitcher";

import { ENUM_CLAIM_REQUEST_DETAIL_STATUS } from "constants/enums/claim_request_detail_status.enum";
import { PERMISSIONS } from "constants/enums/permissions";
import {
  filterOutAllOption,
  ClaimRequestMappingStatus,
} from "constants/objects/claim_request_mapping_status";
import withPagination, { PaginationKeys } from "enhancers/withPagination";
import { useLazyQuery } from "hooks";
import { get, isEmpty } from "lodash";
import { claimRequestPaymentCycleFilterInitialValues } from "./PaymentCycleDetailFilters";
import { ReactComponent as ViewIcon } from "assets/icon/view-icon.svg";
import { CircularProgress } from "@material-ui/core";

const PaymentCycleDetailTableComponent = ({
  title,
  claimRequestIdList,
  t,
  columns = [],
  tableData = [],
  loading,
  handleSelectionChange,
  isCheckboxDisabled,
  paginationData,
  totalItems,
  refetchData,
  handleSortModelChange,
}: any) => {
  if (loading)
    return (
      <Modal
        open={true}
        onClose={() => {}}
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.7)", // เพิ่มความมืดให้พื้นหลัง
          },
        }}
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box
            bgcolor="white"
            p={3}
            borderRadius={8}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <CircularProgress />
            <Typography variant="h6">Loading...</Typography>
          </Box>
        </Box>
      </Modal>
    );
  return (
    <Box
      border={`1px solid ${AppColor["Text/Line"]}`}
      padding="22px 20px"
      borderRadius="8px"
    >
      <Box display="flex" justifyContent="space-between">
        <Typography variant="Header/20">{title}</Typography>
        <Typography variant="Helper/12">{t(".helperText")}</Typography>
      </Box>
      <PaymentCycleSwitcher
        fetchDataLoading={loading}
        onCompleted={refetchData}
        claimRequestIdList={claimRequestIdList}
      />

      <div style={{ marginTop: "32px" }}>
        <Table
          columns={columns}
          rows={tableData}
          autoHeight={false}
          autoPageSize={false}
          disableSelectionOnClick
          checkboxSelection
          onSelectionModelChange={handleSelectionChange}
          selectionModel={claimRequestIdList}
          componentsProps={{
            checkbox: {
              disabled: isCheckboxDisabled,
            },
          }}
          // page options
          page={paginationData.page - 1}
          pageSize={paginationData.limit}
          onPageChange={paginationData.handlePageChange}
          onPageSizeChange={paginationData.handlePageSizeChange}
          rowsPerPageOptions={[25, 50, 100]}
          rowCount={totalItems}
          paginationMode="server"
          // page options

          onSortModelChange={handleSortModelChange}
          isShowHover
          style={{
            minHeight: 606,
            height: "100vh",
          }}
        />
      </div>
    </Box>
  );
};

export const PAYMENT_CYCLE_DETAIL_TABLE_API = {
  GET_CLAIM_REQUEST_PAYMENT_CYCLE: gql`
    query GET_CLAIM_REQUEST_PAYMENT_CYCLE(
      $paymentCycleId: String
      $filters: JSON
    ) {
      claimRequestsWithPaymentCycle(
        input: { paymentCycleId: $paymentCycleId, filters: $filters }
      ) {
        data
        pagination
      }
    }
  `,
};

const enhancer = compose(
  withAuthorize(),
  withPagination({
    defaultLimit: 25,
    routeParamsKey: "id",
    navigateToPath: ({ queryParams, routeParam }) => {
      paths.paymentCycleDetailPath(routeParam, queryParams).push();
    },
    rowsPerPageOptions: [25, 50, 100],
  }),
  withTranslation({
    prefix: "pages.main.paymentCycles.detail.PaymentCycleDetailTable",
  }),
  withHooks((props: any, hooks: any) => {
    const { t, isPaymentCycleClosed, hasPermission, paginationData } = props;
    const { useParams, useMemo, useState, useEffect, useUrlParam } = hooks;
    const queryParams = useUrlParam();

    const [selected, setSelected] = useState([]);
    const [isRefetching, setIsRefetching] = useState(false);

    const { id } = useParams();

    const isShowPaymentCycleDetail = useMemo(() => id !== waiting_requests_id, [
      id,
    ]);

    const [fetchList, { data, loading, error }] = useLazyQuery(
      PAYMENT_CYCLE_DETAIL_TABLE_API.GET_CLAIM_REQUEST_PAYMENT_CYCLE,
      {
        fetchPolicy: "network-only",
      }
    );

    const columns = useMemo(
      () => [
        {
          width: 300,
          field: "referenceId",
          headerName: t(".refId") || "",
        },
        {
          width: 200,
          field: "requesterName",
          headerName: t(".claimRequester") || "",
        },
        {
          width: 150,
          field: "department",
          headerName: t(".department") || "",
          type: "string",
        },
        {
          width: 150,
          field: "claimType",
          headerName: t(".claimType") || "",
          type: "string",
        },
        {
          width: 250,
          field: "claimName",
          headerName: t(".claimName") || "",
          type: "string",
        },
        {
          width: 100,
          field: "createdAt",
          headerName: t(".createdAt") || "",
          type: "string",
          renderCell: (createdAt: any) => {
            return dayjs(createdAt.value)
              .locale("th")
              .add(543, "year")
              .format("DD/MM/YYYY");
          },
        },
        {
          width: 150,
          field: "requestAmount",
          headerName: t(".requestAmount") || "",
          type: "currency",
          filterable: false,
          sortable: false,
        },
        {
          width: 150,
          field: "displayStatus",
          headerName: t(".status") || "",
          type: "string",
          renderCell: (status: any) => {
            return ClaimRequestMappingStatus[
              status.value as ENUM_CLAIM_REQUEST_DETAIL_STATUS
            ];
          },
        },

        {
          width: 150,
          field: "receiptNo",
          headerName: t(".refNo") || "",
          type: "string",
        },
        {
          width: 150,
          field: "receiptDate",
          headerName: t(".transferDate") || "",
          type: "string",
          renderCell: (endDate: any) => {
            return endDate.value
              ? dayjs(endDate.value)
                  .locale("th")
                  .add(543, "year")
                  .format("DD/MM/YYYY")
              : "";
          },
        },
        {
          width: 100,
          field: "actions",
          headerName: " ",
          filterable: false,
          sortable: false,
          type: "actions",
        },
      ],
      [t]
    );

    const handleClickToSeeDetail = useCallback((row: any) => {
      paths.requestDetailPath(row.id).push();
    }, []);

    const tableData = useMemo(() => {
      if (loading || error) {
        return [];
      }

      return data?.claimRequestsWithPaymentCycle.data.map((req: any) => {
        const { employee, info, status, requestAmount } = req;

        return {
          ...req,
          requesterName: `[${employee.employeeCode}] ${employee.firstName} ${employee.lastName}`,
          requestAmount,
          department: employee.department,
          claimType: info.type,
          claimName: info.title,
          status: status,
          receiptDate: info.values.inputs["receipt_date"]
            ? mapStringDate(info.values.inputs["receipt_date"])
            : "",
          disease: info.values.inputs["disease"] || "",
          receiptNo: info.values.inputs["receipt_no"] || "",
          actions: [
            {
              Icon: ViewIcon,
              onClick: handleClickToSeeDetail,
            },
          ],
        };
      });
    }, [
      loading,
      error,
      data?.claimRequestsWithPaymentCycle.data,
      handleClickToSeeDetail,
    ]);

    const handleSelectionChange = useCallback((newIds: string[]) => {
      setSelected(newIds);
    }, []);

    const title = useMemo(() => {
      if (isShowPaymentCycleDetail) return t(".title");
      return t(".waitingTitle");
    }, [t, isShowPaymentCycleDetail]);

    const isCheckboxDisabled = useMemo(() => {
      return (
        !hasPermission([PERMISSIONS.PAYMENT_CYCLE_EDIT]) || isPaymentCycleClosed
      );
    }, [hasPermission, isPaymentCycleClosed]);

    const totalItems = useMemo(
      () => data?.claimRequestsWithPaymentCycle.pagination.total,
      [data?.claimRequestsWithPaymentCycle.pagination.total]
    );

    const handleSortModelChange = (newSortModel: any[]) => {
      const newQueryParams = newSortModel.reduce((acc: any, sort: any) => {
        acc.sortBy = sort.field;
        acc.sortDir = sort.sort;
        return acc;
      }, {});

      const existingQueryParams = { ...queryParams };

      const combinedQueryParams = {
        ...existingQueryParams,
        ...newQueryParams,
      };

      paths.paymentCycleDetailPath(id, combinedQueryParams).push();
    };

    const useRefetchData = ({
      fetchList,
      id,
      queryParams,
      isShowPaymentCycleDetail,
    }: {
      fetchList: any;
      id: string;
      queryParams: any;
      isShowPaymentCycleDetail: boolean;
    }) => {
      const checkInvalidKeys = useCallback((params: any) => {
        if (!params) return false;
        const allowedKeys = [
          ...Object.keys(claimRequestPaymentCycleFilterInitialValues),
          ...Object.keys(PaginationKeys),
          "sortBy",
          "sortDir",
        ];
        return Object.keys(params).some((key) => !allowedKeys.includes(key));
      }, []);

      const refetchData = useCallback(async () => {
        try {
          setIsRefetching(true);
          const params = isEmpty(queryParams) ? undefined : queryParams;
          const variables = {
            paymentCycleId: isShowPaymentCycleDetail ? id : undefined,
            filters: filterOutAllOption(params),
          };
          await fetchList({ variables });
        } finally {
          setIsRefetching(false);
        }
      }, [fetchList, id, isShowPaymentCycleDetail, queryParams]);

      useEffect(() => {
        const params = isEmpty(queryParams) ? undefined : queryParams;
        if (checkInvalidKeys(params)) {
          paths.paymentCycleDetailPath(id).push();
          return;
        }
        refetchData();
      }, [
        queryParams,
        id,
        isShowPaymentCycleDetail,
        checkInvalidKeys,
        refetchData,
      ]);

      return { refetchData };
    };

    const { refetchData } = useRefetchData({
      fetchList,
      id,
      queryParams,
      isShowPaymentCycleDetail,
    });

    return {
      title,
      claimRequestIdList: selected,
      t,
      columns,
      tableData,
      handleSelectionChange,
      isCheckboxDisabled,
      loading: loading || isRefetching,
      paginationData,
      totalItems,
      refetchData,
      handleSortModelChange,
    };
  })
);

const mapStringDate = (dateString: any) => {
  const [day, month, year] = dateString.split("/").map(Number);
  const date = new Date(year, month - 1, day);
  return date;
};

export default enhancer(PaymentCycleDetailTableComponent);
