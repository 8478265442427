import { Box, Table, Typography } from "components";
import { EnumClaimRequestStatus } from "constants/enums/claim-request-status";
import dayjs from "dayjs";
import { compose, withAuthorize, withHooks, withTranslation } from "enhancers";
import withPagination, { PaginationKeys } from "enhancers/withPagination";
import { PageContent } from "layouts";
import { BreadcrumbsProps } from "layouts/PageContent";
import { isEmpty } from "lodash";
import { filterEmptyValues, gql, homePath, paths } from "utils/helper";
import ClaimRequestFilters, {
  claimRequestFilterInitialValues,
} from "./ClaimRequestFilters";

const RequestIndexPage = ({ paginationData, totalItems, ...props }: any) => (
  <PageContent
    title={props.t(".title")}
    breadcrumbs={props.breadcrumbs}
    pageActions={[]}
    filter={<ClaimRequestFilters />}
    widthAuto
  >
    <Box width="100%" mb={-6}>
      <Box mb={4}>
        <Typography variant="h4">{props.t(".title")}</Typography>
      </Box>
      <Table
        columns={props.columns}
        rows={props.tableData}
        loading={props.loading}
        autoHeight
        onRowClickTo={paths.requestDetailPath}
        isShowHover
        page={paginationData.page - 1}
        pageSize={paginationData.limit}
        onPageChange={paginationData.handlePageChange}
        onPageSizeChange={paginationData.handlePageSizeChange}
        rowsPerPageOptions={[25, 50, 100]}
        rowCount={totalItems}
        paginationMode="server"
      />
    </Box>
  </PageContent>
);

const API = {
  GET_DASHBOARD_CLAIM_LIST: gql`
    query GET_DASHBOARD_CLAIM_LIST($filters: JSON, $pagination: JSON) {
      dashboardClaimList(
        input: { filters: $filters, pagination: $pagination }
      ) {
        data {
          id
          referenceId
          employee {
            employeeCode
            firstName
            lastName
            department
          }
          status
          createdAt
          claimName
          claimType
          disease
          receiptNo
          receiptDate
        }
        pagination
      }
    }
  `,
};

const enhancer = compose(
  withAuthorize(),
  withPagination({
    defaultLimit: 25,
    navigateToPath: ({ queryParams }) => {
      paths.requestPath(queryParams).push();
    },
    rowsPerPageOptions: [25, 50, 100],
  }),
  withTranslation({
    prefix: "pages.main.request.index",
  }),
  withHooks((props: any, hooks: any) => {
    const { t, paginationData } = props;
    const { useMemo, useEffect, useUrlParam, useLazyQuery } = hooks;

    const [fetchList, { data, loading, error }] = useLazyQuery(
      API.GET_DASHBOARD_CLAIM_LIST,
      {
        fetchPolicy: "network-only",
      }
    );

    const breadcrumbs = useMemo(
      (): BreadcrumbsProps[] => [
        { label: t(".home"), path: homePath() },
        { label: t(".title"), path: null },
      ],
      [t]
    );

    const columns = useMemo(
      () => [
        {
          width: 300,
          field: "referenceId",
          headerName: t(".refId") || "",
        },
        {
          width: 300,
          field: "requesterName",
          headerName: t(".claimRequester") || "",
        },
        {
          width: 150,
          field: "department",
          headerName: t(".department") || "",
          type: "string",
        },
        {
          width: 150,
          field: "claimType",
          headerName: t(".claimType") || "",
          type: "string",
        },
        {
          width: 250,
          field: "claimName",
          headerName: t(".claimName") || "",
          type: "string",
        },
        {
          width: 150,
          field: "createdAt",
          headerName: t(".createdAt") || "",
          type: "string",
          renderCell: (createdAt: any) => {
            return dayjs(createdAt.value)
              .locale("th")
              .add(543, "year")
              .format("DD/MM/YYYY");
          },
        },
        {
          width: 150,
          field: "status",
          headerName: t(".status") || "",
          type: "string",
        },
        {
          width: 150,
          field: "disease",
          headerName: t(".disease") || "",
          type: "string",
        },
        {
          width: 150,
          field: "receiptNo",
          headerName: t(".refNo") || "",
          type: "string",
        },
        {
          width: 150,
          field: "receiptDate",
          headerName: t(".transferDate") || "",
          type: "string",
        },
      ],
      [t]
    );

    const queryParams = useUrlParam();
    useEffect(() => {
      const params = isEmpty(queryParams) ? undefined : queryParams;
      const allowedKeys = Object.keys(claimRequestFilterInitialValues);
      const hasInvalidKey =
        params &&
        Object.keys(params).some(
          (key) =>
            ![...allowedKeys, ...Object.keys(PaginationKeys)].includes(key)
        );

      console.log({ hasInvalidKey, allowedKeys });

      if (hasInvalidKey || !params) {
        paths
          .requestPath(filterEmptyValues(claimRequestFilterInitialValues))
          .push();
      } else {
        fetchList({
          variables: {
            filters: {
              department: params.department ? params.department : "all",
              claimType: params.claimType ? params.claimType : "all",
              claimName: params.claimName ? params.claimName : "all",
              disease: params.disease ? params.disease : "all",
              status: params.disease ? params.disease : "ALL",
              ...params,
            },
            pagination: { offset: params.page, limit: params.limit },
          },
        });
      }
    }, [queryParams, fetchList]);

    const totalItems = useMemo(
      () => data?.dashboardClaimList.pagination.total,
      [data?.dashboardClaimList.pagination.total]
    );

    const tableData = useMemo(() => {
      if (loading || error) {
        return [];
      }

      const dataItems = data?.dashboardClaimList.data.map((req: any) => {
        const { employee, info, status } = req;

        return {
          ...req,
          requesterName: `[${employee.employeeCode}] ${employee.firstName} ${employee.lastName}`,
          department: employee.department,
          status: mapStatus(status),
        };
      });
      return dataItems || [];
    }, [data?.dashboardClaimList.data, error, loading]);

    return {
      breadcrumbs,
      tableData,
      columns,
      loading,
      paginationData,
      totalItems,
    };
  })
);

const mapStatus = (status: any) => {
  switch (status) {
    case EnumClaimRequestStatus.WAITING:
      return "รออนุมัติ";
    case EnumClaimRequestStatus.APPROVED:
      return "อนุมัติ";
    case EnumClaimRequestStatus.REJECTED:
      return "ไม่อนุมัติ";
    case EnumClaimRequestStatus.CANCELED:
      return "ยกเลิก";
    default:
      return "บันทึกร่าง";
  }
};

// const mapStringDate = (dateString: any) => {
//   const [day, month, year] = dateString.split("/").map(Number);
//   const date = new Date(year, month - 1, day);
//   return date;
// };

export default enhancer(RequestIndexPage);
