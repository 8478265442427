import { ENUM_CLAIM_REQUEST_DETAIL_STATUS } from "constants/enums/claim_request_detail_status.enum";

export const ClaimRequestMappingStatus = {
  [ENUM_CLAIM_REQUEST_DETAIL_STATUS.APPROVED]: "อนุมัติ",
  [ENUM_CLAIM_REQUEST_DETAIL_STATUS.WAITING]: "รออนุมัติ",
  [ENUM_CLAIM_REQUEST_DETAIL_STATUS.WAITING_0]: "รอผู้บังคับบัญชาอนุมัติ",
  [ENUM_CLAIM_REQUEST_DETAIL_STATUS.WAITING_1]: "รอฝ่ายบุคคลอนุมัติ",
  [ENUM_CLAIM_REQUEST_DETAIL_STATUS.WAITING_2]: "รอผู้จัดการกลุ่มฯ อนุมัติ",
  [ENUM_CLAIM_REQUEST_DETAIL_STATUS.WAITING_3]: "รอผู้อำนวยการฝ่ายฯ อนุมัติ",
  [ENUM_CLAIM_REQUEST_DETAIL_STATUS.WAITING_4]: "รอผู้ช่วย ปจบ. อนุมัติ",
  [ENUM_CLAIM_REQUEST_DETAIL_STATUS.WAITING_5]: "รอรอง ปจบ. อนุมัติ",
  [ENUM_CLAIM_REQUEST_DETAIL_STATUS.WAITING_6]: "รอปจบ. อนุมัติ",
  [ENUM_CLAIM_REQUEST_DETAIL_STATUS.WAITING_EMPLOYEE_UPDATE]: "รอพนักงานแก้ไข",
  [ENUM_CLAIM_REQUEST_DETAIL_STATUS.WAITING_HR_UPDATE]: "รอฝ่ายบุคคลแก้ไข",
};

export const allOption = { label: "ทั้งหมด", value: "all" };
export const filterOutAllOption = (values: any) => {
  // console.log({ values });
  if (values) {
    // ตรวจสอบว่า options คือ object ที่สามารถใช้ Object.entries ได้
    if (typeof values !== "object" || values === null) {
      throw new TypeError("The provided argument is not an object.");
    }

    const filteredOptions = Object.fromEntries(
      Object.entries(values).filter(([key, value]) => value !== allOption.value)
    );
    return filteredOptions;
  }
  return {};
};
