import { withTranslation } from "react-i18next";
import * as hooks from "hooks";
import { get } from "lodash";
import { paths, replaceNameSpace } from "utils/helper";
import withHooks from "./withHooks";

export const PaginationKeys = {
  page: "page",
  limit: "limit",
};

export const SortingKeys = {
  sortBy: "sortBy",
  sortDir: "sortDir",
};

interface IWithPaginationProps {
  defaultLimit: number;
  routeParamsKey?: any;
  navigateToPath: (params: { routeParam?: string; queryParams?: any }) => void;
  rowsPerPageOptions: number[];
}

const withPagination = (outerProps: IWithPaginationProps) =>
  withHooks((props: any, hooks: any) => {
    const { useCallback, useRef, useMemo, useUrlParam, useParams } = hooks;
    const {
      defaultLimit,
      routeParamsKey,
      navigateToPath,
      rowsPerPageOptions,
    } = outerProps;

    const queryParams = useUrlParam();
    const routeParamsQry = useParams();

    const initialPaginationData = useMemo(
      () => ({ page: 1, limit: defaultLimit }),
      [defaultLimit]
    );
    const routeParam = get(routeParamsQry, routeParamsKey);
    const validateLimit = useCallback(
      (pageSize: number) => {
        if (rowsPerPageOptions.includes(pageSize)) {
          return pageSize;
        }
        return defaultLimit;
      },
      [defaultLimit, rowsPerPageOptions]
    );
    const paginationData = useMemo(() => {
      const page = get(queryParams, PaginationKeys.page);
      const limit = get(queryParams, PaginationKeys.limit);

      if (!page || !limit) {
        navigateToPath({
          routeParam,
          queryParams: { ...queryParams, ...initialPaginationData },
        });
        return initialPaginationData;
      }

      const numberPage = Number(page);
      const numberLimit = Number(limit);
      const validatedLimit = validateLimit(numberLimit);

      if (validatedLimit !== numberLimit) {
        navigateToPath({
          routeParam,
          queryParams: { ...queryParams, ...initialPaginationData },
        });
        return initialPaginationData;
      }

      return { page: numberPage, limit: validatedLimit };
    }, [
      initialPaginationData,
      navigateToPath,
      queryParams,
      routeParam,
      validateLimit,
    ]);

    const handlePageChange = (newPageIndex: number) => {
      const { limit } = paginationData;
      navigateToPath({
        routeParam,
        queryParams: { ...queryParams, page: newPageIndex + 1, limit },
      });
    };

    const handlePageSizeChange = (newPageSize: number) => {
      const { page } = paginationData;
      const newLimit = validateLimit(newPageSize);
      navigateToPath({
        routeParam,
        queryParams: { ...queryParams, page, limit: newLimit },
      });
    };

    return {
      ...props,
      paginationData: {
        ...paginationData,
        handlePageChange,
        handlePageSizeChange,
      },
    };
  });

export default withPagination;
