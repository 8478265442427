import { Typography } from "components";
import { compose, withHooks, withTranslation } from "enhancers";
import PageContent, { BreadcrumbsProps } from "layouts/PageContent";
import paths from "routes/paths";
import { gql, homePath } from "utils/helper";
import { waiting_requests_id } from "../PaymentCycleTable";
import PaymentCycleDetailSection from "./PaymentCycleDetailSection";
import PaymentCycleDetailTable from "./PaymentCycleDetailTable";
import PaymentCycleDetailFilters from "./PaymentCycleDetailFilters";
import { EnumPaymentCycleStatus } from "constants/enums/payment_cycle_status";

const PaymentCycleDetailPage = ({
  t,
  breadcrumbs,
  paymentCycleName,
  isPaymentCycleClosed,
}: any) => (
  <PageContent
    widthAuto
    title={paymentCycleName}
    breadcrumbs={breadcrumbs}
    topContent={
      <PaymentCycleDetailSection isPaymentCycleClosed={isPaymentCycleClosed} />
    }
  >
    <Typography variant="Header/20">{t(".claimRequests")}</Typography>
    <PaymentCycleDetailFilters />
    <PaymentCycleDetailTable isPaymentCycleClosed={isPaymentCycleClosed} />
  </PageContent>
);

export const PAYMENT_CYCLE_DETAIL_API = {
  GET_PAYMENT_CYCLE_DETAIL: gql`
    query GET_PAYMENT_CYCLE_DETAIL($id: String) {
      paymentCycleDetail(id: $id) {
        id
        name
        status
        paymentDate
        requestAmount
        requestTotalPaymentAmount
      }
    }
  `,
  UPDATE_PAYMENT_CYCLE_DETAIL: gql`
    mutation UPDATE_PAYMENT_CYCLE_DETAIL(
      $id: String!
      $name: String
      $paymentDate: String
      $status: String
    ) {
      updatePaymentCycleDetail(
        input: {
          id: $id
          name: $name
          paymentDate: $paymentDate
          status: $status
        }
      ) {
        id
      }
    }
  `,
  UPDATE_PAYMENT_CYCLE_STATUS: gql`
    mutation UPDATE_PAYMENT_CYCLE_STATUS($id: String!, $status: String) {
      switchPaymentCycleStatus(input: { id: $id, status: $status }) {
        id
      }
    }
  `,
};

const enhancer = compose(
  withTranslation({
    prefix: "pages.main.paymentCycles.detail.index",
  }),
  withHooks((props: any, hooks: any) => {
    const { t } = props;
    const { useMemo, useQuery, useParams } = hooks;

    const { id } = useParams();
    const isShowPaymentCycleDetail = useMemo(() => id !== waiting_requests_id, [
      id,
    ]);
    const { data } = useQuery(
      PAYMENT_CYCLE_DETAIL_API.GET_PAYMENT_CYCLE_DETAIL,
      {
        variables: { id: isShowPaymentCycleDetail ? id : undefined },
        fetchPolicy: "network-only",
      }
    );

    const paymentCycleName = useMemo(
      () =>
        isShowPaymentCycleDetail ? data?.paymentCycleDetail.name : t(".title"),
      [data?.paymentCycleDetail.name, isShowPaymentCycleDetail, t]
    );

    const breadcrumbs = useMemo(
      (): BreadcrumbsProps[] => [
        { label: t(".home"), path: homePath() },
        { label: t(".paymentCycle"), path: paths.paymentCyclePath() },
        { label: paymentCycleName, path: null },
      ],
      [paymentCycleName, t]
    );

    const isPaymentCycleClosed = useMemo(
      () => data?.paymentCycleDetail.status === EnumPaymentCycleStatus.CLOSED,
      [data?.paymentCycleDetail.status]
    );

    return { t, breadcrumbs, paymentCycleName, isPaymentCycleClosed };
  })
);

export default enhancer(PaymentCycleDetailPage);
