import {
  Box,
  Button,
  Field,
  Form,
  Modal,
  Notification,
  Paper,
  Select,
  Typography,
} from "components";
import { compose, withFormik, withHooks, withTranslation } from "enhancers";

import { get, keyBy } from "lodash";
import { AppColor } from "theme/app-color";
import {
  PAYMENT_CYCLE_TABLE_API,
  waiting_requests_id,
} from "../PaymentCycleTable";

import { CircularProgress } from "@material-ui/core";
import { EnumPaymentCycleStatus } from "constants/enums/payment_cycle_status";
import { useCallback, useState } from "react";
import { formatDateWithBuddhistYear, gql } from "utils/helper";
import { PAYMENT_CYCLE_DETAIL_API } from ".";
import { PAYMENT_CYCLE_DETAIL_TABLE_API } from "./PaymentCycleDetailTable";

const PaymentCycleSwitcherComponent = ({
  t,
  claimRequestIdList = [],
  paymentCycleOptions,
  isSubmitButtonDisabled,
  loading,
}: any) => {
  if (loading)
    return (
      <Modal
        open={true}
        onClose={() => {}}
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.7)", // เพิ่มความมืดให้พื้นหลัง
          },
        }}
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box
            bgcolor="white"
            p={3}
            borderRadius={8}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <CircularProgress />
            <Typography variant="h6">Loading...</Typography>
          </Box>
        </Box>
      </Modal>
    );
  if (claimRequestIdList.length === 0) return <></>;
  return (
    <Paper
      my={6}
      px={4}
      py={2}
      elevation={24}
      style={{
        backgroundColor: AppColor["Other Light/info"],
        borderRadius: "8px",
      }}
    >
      <Form>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box
            display="flex"
            alignItems="center"
            style={{
              gap: "8px",
              width: "153px",
            }}
          >
            <Typography variant="Body Bold" noWrap>
              {t(".selected", { selected: claimRequestIdList.length })}
            </Typography>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            style={{
              gap: "8px",
              width: "760px",
            }}
          >
            <Typography variant="Body/14" noWrap>
              {t(`.moveAllTo`)}
            </Typography>

            <Box flex={1}>
              <Field
                component={Select}
                name="paymentCycleId"
                options={paymentCycleOptions}
                placeholder="โปรดระบุรอบ"
                required
              />
            </Box>

            <Button
              type="submit"
              variant="outlined"
              disabled={isSubmitButtonDisabled}
              style={{
                minWidth: "120px",
                marginLeft: "8px",
                whiteSpace: "nowrap",
              }}
            >
              {t(".moveAndSave")}
            </Button>
          </Box>
        </Box>
      </Form>
    </Paper>
  );
};

const API = {
  CHANGE_REQUEST_PAYMENT_CYCLE: gql`
    mutation CHANGE_REQUEST_PAYMENT_CYCLE(
      $toPaymentCycleId: String
      $fromPaymentCycleId: String
      $claimRequestIdList: [JSON!]
      $filters: JSON
    ) {
      changeClaimRequestPaymentCycle(
        input: {
          toPaymentCycleId: $toPaymentCycleId
          fromPaymentCycleId: $fromPaymentCycleId
          claimRequestIdList: $claimRequestIdList
          filters: $filters
        }
      ) {
        id
      }
    }
  `,
};

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({
      paymentCycleId: "",
    }),
  }),
  withTranslation({
    prefix: "pages.main.paymentCycles.detail.PaymentCycleSwitcher",
  }),
  withHooks((props: any, hooks: any) => {
    const {
      t,
      claimRequestIdList,
      isSubmitting,
      dirty,
      resetForm,
      fetchDataLoading,
      onCompleted,
      values,
    } = props;
    const {
      useQuery,
      useParams,
      useHandleSubmit,
      useMemo,
      useMutation,
      useEffect,
      useUrlParam,
    } = hooks;

    const { id } = useParams();
    const queryParams = useUrlParam();
    const isShowPaymentCycleDetail = useMemo(() => id !== waiting_requests_id, [
      id,
    ]);

    const [isProcessing, setIsProcessing] = useState(false);

    const { data } = useQuery(PAYMENT_CYCLE_TABLE_API.PAYMENT_CYCLE_LIST, {
      variables: { status: EnumPaymentCycleStatus.IN_PROGRESS },
      fetchPolicy: "network-only",
    });
    const [changePaymentCycle, { loading: mutationLoading }] = useMutation(
      API.CHANGE_REQUEST_PAYMENT_CYCLE,
      {
        skipSetError: true,
        onCompleted: async () => {
          resetForm();
          Notification.notify(t("client.success"));
          await onCompleted();
          setIsProcessing(false);
        },
        onError: (props: any) => {
          setIsProcessing(false);
          const message = get(
            props,
            "graphQLErrors[0].extensions.extensions.originalError[0].message",
            ""
          );

          if (message === "Unable to change payment cycle") {
            Modal.alert({
              title: t(".errorModal.title"),
              children: (
                <div>
                  <Typography variant="Body/16">
                    {t(".errorModal.info1")}
                  </Typography>
                  &nbsp;
                  <Typography
                    variant="Subtitle/16"
                    color={AppColor["Primary/Primary"]}
                  >
                    {`อนุมัติ`}
                  </Typography>
                  &nbsp;
                  <Typography variant="Body/16">
                    {t(".errorModal.info2")}
                  </Typography>
                  <br />
                  &nbsp;
                  <Typography variant="Body/16">
                    {t(".errorModal.info3")}
                  </Typography>
                </div>
              ),
              okButtonLabel: t(`.errorModal.confirm`),
              okButtonVariant: "contained",
              okButtonColor: AppColor["Primary/Primary"],
              okButtonWidth: "70px",
              onOk: async ({ close }: any) => {
                close();
              },
              onCancel: undefined,
            });
          }
        },
        refetchQueries: [
          {
            query:
              PAYMENT_CYCLE_DETAIL_TABLE_API.GET_CLAIM_REQUEST_PAYMENT_CYCLE,
            variables: {
              paymentCycleId: isShowPaymentCycleDetail ? id : undefined,
              filters: { ...queryParams },
            },
          },
          {
            query: PAYMENT_CYCLE_DETAIL_API.GET_PAYMENT_CYCLE_DETAIL,
            variables: { id: isShowPaymentCycleDetail ? id : undefined },
          },
          {
            query: PAYMENT_CYCLE_DETAIL_API.GET_PAYMENT_CYCLE_DETAIL,
            variables: { id: undefined },
          },
        ],
        awaitRefetchQueries: true,
      }
    );

    const paymentCycleOptions = useMemo(() => {
      const waitingOptions = isShowPaymentCycleDetail
        ? [
            {
              label: t(".waitingOptions"),
              value: waiting_requests_id,
            },
          ]
        : [];

      const paymentCycles = (data?.paymentCycleList ?? [])
        .filter((cycle: any) => cycle.id !== id)
        .map((cycle: any) => ({
          label: `[${formatDateWithBuddhistYear(
            cycle.paymentDate,
            "dd/MM/yyyy"
          )}] ${cycle.name}`,
          value: cycle.id,
        }));

      return [...waitingOptions, ...paymentCycles];
    }, [data?.paymentCycleList, id, isShowPaymentCycleDetail, t]);

    useHandleSubmit(async (values: any) => {
      const optionMapper = keyBy(paymentCycleOptions, "value");
      const name = optionMapper[values.paymentCycleId].label;
      Modal.open({
        title: t(".changePaymentModal.title"),
        children: (
          <div>
            <Typography variant="Body/16">
              {t(".changePaymentModal.info1")}
            </Typography>
            &nbsp;
            <Typography
              variant="Subtitle/16"
              color={AppColor["Primary/Primary"]}
            >
              {`${claimRequestIdList.length} รายการ`}
            </Typography>
            &nbsp;
            <Typography variant="Body/16">
              {t(".changePaymentModal.info2")}
            </Typography>
            &nbsp;
            <Typography
              variant="Subtitle/16"
              color={AppColor["Primary/Primary"]}
            >
              {`"${name}"`}
            </Typography>
            &nbsp;
            <Typography variant="Body/16">
              {t(".changePaymentModal.info3")}
            </Typography>
          </div>
        ),
        okButtonLabel: t(`.changePaymentModal.confirm`),
        okButtonVariant: "contained",
        okButtonColor: AppColor["Primary/Primary"],
        cancelButtonLabel: t(".changePaymentModal.cancel"),
        cancelButtonVariant: "outlined",
        onOk: async ({ close }: any) => {
          close();
          await handleConfirmChangePaymentCycle(values);
        },
      });
    });

    const handleConfirmChangePaymentCycle = useCallback(
      async (values: any) => {
        setIsProcessing(true);
        const variables = {
          fromPaymentCycleId: isShowPaymentCycleDetail ? id : undefined,
          toPaymentCycleId:
            values.paymentCycleId === waiting_requests_id
              ? undefined
              : values.paymentCycleId,
          claimRequestIdList,
        };
        await changePaymentCycle({ variables });
      },
      [changePaymentCycle, claimRequestIdList, id, isShowPaymentCycleDetail]
    );

    const isSubmitButtonDisabled = useMemo(() => {
      return isSubmitting || !dirty || !values.paymentCycleId;
    }, [isSubmitting, dirty, values.paymentCycleId]);

    useEffect(() => {
      if (claimRequestIdList.length === 0) resetForm();
    }, [claimRequestIdList.length, resetForm]);

    return {
      t,
      claimRequestIdList,
      paymentCycleOptions,
      isSubmitButtonDisabled,
      loading: mutationLoading || isProcessing || fetchDataLoading,
    };
  })
);

export default enhancer(PaymentCycleSwitcherComponent);
