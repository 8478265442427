import { compose, withFormik, withHooks, withTranslation } from "enhancers";
import { PageContent } from "layouts";
import {
  Box,
  Table,
  Typography,
  Form,
  Grid,
  Field,
  Select,
  Button,
} from "components";
import { gql, homePath, paths } from "utils/helper";
import { isEmpty } from "lodash";
import { BreadcrumbsProps } from "layouts/PageContent";
import { GridColDef } from "@material-ui/data-grid";
import { ReactComponent as FilterIcon } from "assets/icon/filter_outline.svg";
import styled from "styled-components";
import { AppColor } from "theme/app-color";
import { TextField } from "components";

const FilterCountNotify = styled(Box)`
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #ffa500;
  display: flex;
`;

const BudgetIndexPage = (props: any) => (
  <PageContent
    title={props.t(".title")}
    breadcrumbs={props.breadcrumbs}
    pageActions={[]}
    filter={props.filterSection}
  >
    <Box width="100%" mb={-6}>
      <Typography variant="h4">{props.t(".title")}</Typography>
      <Table
        columns={props.columns}
        rows={props.tableData}
        loading={props.loading}
        onRowClickTo={paths.budgetEditPath}
        density="compact"
        autoHeight
        disableSelectionOnClick
      />
    </Box>
  </PageContent>
);

export const API = {
  FETCH_EMPLOYEE_YEARLY_BUDGET: gql`
    query FETCH_EMPLOYEE_YEARLY_BUDGET($filters: JSON) {
      getEmployeeYearlyBudgets(input: { filters: $filters }) {
        id
        employeeCode
        firstName
        lastName
        year
        budget
        dentalBudget
        companyBudget
        companyDentalBudget
      }
    }
  `,
  FETCH_OPTIONS: gql`
    query FETCH_OPTIONS {
      getYearlyBudgetOption {
        label
        value
      }
    }
  `,
};

const enhancer = compose(
  withFormik({}),
  withTranslation({
    prefix: "pages.main.budget.index",
  }),
  withHooks((props: any, hooks: any) => {
    const { t, setValues, setInitialValues } = props;
    const {
      useState,
      useMemo,
      useCallback,
      useQuery,
      useEffect,
      useHandleSubmit,
    } = hooks;

    useEffect(() => {
      setInitialValues({
        year: "ทั้งหมด",
      });
    }, []);

    const [showFilter, setShowFilter] = useState(false);
    const [filterCount, setFilterCount] = useState(0);

    const { loading, data, error, refetch } = useQuery(
      API.FETCH_EMPLOYEE_YEARLY_BUDGET,
      {
        variables: { filters: { year: "ทั้งหมด" } },
        fetchPolicy: "network-only",
      }
    );

    const { data: options } = useQuery(API.FETCH_OPTIONS, {
      fetchPolicy: "network-only",
    });

    const yearlyRequestOptions = useMemo(() => {
      return [{ label: "ทั้งหมด", value: "ทั้งหมด" }].concat(
        options?.getYearlyBudgetOption
      );
    }, [options]);

    const handleShowFilter = useCallback(() => {
      setShowFilter(!showFilter);
    }, [showFilter]);

    const clearFilter = useCallback(async () => {
      setValues({
        code: "",
        fullName: "",
        year: "ทั้งหมด",
      });
      const variables = {
        filters: {
          code: "",
          fullName: "",
          year: "ทั้งหมด",
        },
      };
      setFilterCount(0);
      await refetch(variables);
    }, [setValues]);

    const breadcrumbs = useMemo(
      (): BreadcrumbsProps[] => [
        { label: t(".home"), path: homePath() },
        { label: t(".title"), path: null },
      ],
      [t]
    );

    const filterSection = useMemo(
      () => (
        <Box>
          <Form>
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" alignItems="center">
                <FilterIcon />
                <Typography variant="h6">{t(".filter.title")}</Typography>
                {filterCount > 0 && (
                  <FilterCountNotify>
                    <Typography variant="body1">{filterCount}</Typography>
                  </FilterCountNotify>
                )}
              </Box>
              <Box
                display="flex"
                alignItems="center"
                onClick={handleShowFilter}
              >
                <Typography
                  variant="body1"
                  style={{
                    textDecoration: "underline",
                    color: AppColor["Primary/Primary Text"],
                    cursor: "pointer",
                  }}
                >
                  {showFilter ? t(".filter.hide") : t(".filter.show")}
                </Typography>
              </Box>
            </Box>
            {showFilter && (
              <Box display="flex" flexDirection="column">
                <Grid container spacing={6} mt={6}>
                  <Grid item xs={4}>
                    <Field
                      component={TextField}
                      name="code"
                      type="text"
                      label={t(".filter.code")}
                      fullWidth
                      disabled={loading}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      component={TextField}
                      name="fullName"
                      type="text"
                      label={t(".filter.fullName")}
                      fullWidth
                      disabled={loading}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      component={Select}
                      name="year"
                      type="text"
                      label={t(".filter.yearlyRequest")}
                      options={yearlyRequestOptions}
                      fullWidth
                      disabled={loading}
                    />
                  </Grid>
                </Grid>
                <Box display="flex" flexDirection="row-reverse" mt={4}>
                  <Button width={74} type="submit" disabled={loading}>
                    {t(".filter.confirm")}
                  </Button>
                  <Button
                    width={74}
                    mr={6}
                    onClick={clearFilter}
                    variant="outlined"
                    disabled={loading}
                  >
                    {t(".filter.reset")}
                  </Button>
                </Box>
              </Box>
            )}
          </Form>
        </Box>
      ),
      [t, filterCount, handleShowFilter, showFilter, loading]
    );

    useHandleSubmit(
      async (values: any) => {
        if (values.year === "ทั้งหมด") {
          setFilterCount(
            Math.max(Object.values(values).filter(Boolean).length - 1, 0)
          );
        } else {
          setFilterCount(Object.values(values).filter(Boolean).length);
        }

        const variables = {
          filters: {
            code: values.code,
            fullName: values.fullName,
            year: values.year,
          },
        };
        await refetch(variables);
      },
      [refetch]
    );

    const tableData = useMemo(() => {
      if (loading || error) {
        return [];
      }
      return data.getEmployeeYearlyBudgets.map((yearly: any) => {
        const {
          id,
          year,
          budget,
          dentalBudget,
          employeeCode,
          firstName,
          lastName,
          companyBudget,
          companyDentalBudget,
        } = yearly;

        return {
          id: id,
          code: employeeCode,
          fullName:
            (isEmpty(firstName) || firstName == null) &&
            (isEmpty(lastName) || lastName == null)
              ? null
              : `${firstName || ""} ${lastName || ""}`,
          year: year,
          budget: budget,
          dentalBudget: dentalBudget,
          companyBudget,
          companyDentalBudget,
        };
      });
    }, [loading, error, data]);
    const formatter = new Intl.NumberFormat("th-TH", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const columns = useMemo(
      (): GridColDef[] => [
        {
          width: 150,
          field: "code",
          headerName: t(".columns.code"),
        },
        {
          width: 300,
          field: "fullName",
          headerName: t(".columns.fullName"),
        },
        {
          width: 130,
          field: "year",
          headerName: t(".columns.year"),
        },
        {
          width: 150,
          field: "companyBudget",
          headerName: t(".columns.companyBudget"),
          type: "number",
          renderCell: (companyBudget: any) => {
            return formatter.format(companyBudget.value);
          },
        },
        {
          width: 150,
          field: "budget",
          headerName: t(".columns.budget"),
          renderCell: (budget: any) => {
            return formatter.format(budget.value);
          },
        },
        {
          width: 150,
          field: "companyDentalBudget",
          headerName: t(".columns.companyDentalBudget"),
          renderCell: (companyDentalBudget: any) => {
            return formatter.format(companyDentalBudget.value);
          },
        },
        {
          width: 150,
          field: "dentalBudget",
          headerName: t(".columns.dentalBudget"),
          renderCell: (dentalBudget: any) => {
            return formatter.format(dentalBudget.value);
          },
        },
      ],
      [t]
    );

    return {
      breadcrumbs,
      tableData,
      loading,
      columns,
      handleShowFilter,
      showFilter,
      filterSection,
    };
  })
);

export default enhancer(BudgetIndexPage);
