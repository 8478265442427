import {
  Box,
  DatePicker,
  Field,
  FilterTableWithQueryParams,
  Grid,
  TextField,
} from "components";
import Select from "components/common/Select";
import { EnumClaimRequestStatus } from "constants/enums/claim-request-status";
import { compose, withAuthorize, withHooks, withTranslation } from "enhancers";
import { createYupDateObject, gql, paths, Yup } from "utils/helper";

export const claimRequestFilterInitialValues = {
  referenceId: "",
  requesterId: undefined,
  department: "all",
  claimType: "all",
  claimName: "all",
  disease: "all",
  status: "ALL",
  receiptNo: "",
  startDate: undefined,
  endDate: undefined,
  startRecieptDate: undefined,
  endRecieptDate: undefined,
};

const validationSchema = Yup.object().shape({
  startDate: createYupDateObject("startDate", "endDate", "LTE"),
  endDate: createYupDateObject("endDate", "startDate", "GTE"),
  startRecieptDate: createYupDateObject(
    "startRecieptDate",
    "endRecieptDate",
    "LTE"
  ),
  endRecieptDate: createYupDateObject(
    "endRecieptDate",
    "startRecieptDate",
    "GTE"
  ),
});

const ClaimRequestFilters = ({
  diseaseOption,
  departmentOption,
  claimStatusOptions,
  claimTypeOptions,
  claimNameOptions,
  employeesOptions,
}: any) => (
  <FilterTableWithQueryParams
    initialValues={claimRequestFilterInitialValues}
    path={paths.requestPath}
    validationSchema={validationSchema}
  >
    <Box display="flex" flexDirection="column">
      <Grid container mt={6}>
        <Box>
          <Grid container spacing={7} mb={4}>
            <Grid item xs={3}>
              <Field
                component={TextField}
                name="referenceId"
                type="text"
                label="เลขอ้างอิง"
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                component={Select}
                name="department"
                type="text"
                label="ฝ่ายสังกัด"
                options={departmentOption}
                fullWidth
                disableClearable
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={Select}
                name="requesterId"
                type="text"
                label="ผู้ขอเบิก"
                fullWidth
                options={employeesOptions}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={Select}
                name="claimType"
                type="text"
                label="ประเภทสวัสดิการ"
                options={claimTypeOptions}
                fullWidth
                disableClearable
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={Select}
                name="claimName"
                type="text"
                label="ชื่อสวัสดิการ"
                options={claimNameOptions}
                fullWidth
                disableClearable
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={Select}
                name="disease"
                type="text"
                label="โรค"
                options={diseaseOption}
                fullWidth
                disableClearable
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                component={Select}
                name="status"
                type="text"
                label="สถานะ"
                options={claimStatusOptions}
                fullWidth
                disableClearable
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                component={TextField}
                name="receiptNo"
                type="text"
                label="เลขที่ใบเสร็จ"
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                fast={false}
                component={DatePicker}
                name="startRecieptDate"
                label="วันที่ตามใบเสร็จตั้งแต่"
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                fast={false}
                component={DatePicker}
                name="endRecieptDate"
                label="จนถึงวันที่"
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                fast={false}
                component={DatePicker}
                name="startDate"
                label="วันที่สร้างรายการตั้งแต่"
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                fast={false}
                component={DatePicker}
                name="endDate"
                label="จนถึงวันที่"
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Box>
  </FilterTableWithQueryParams>
);

export const API = {
  FETCH_OPTIONS: gql`
    query FETCH_OPTIONS {
      getDepartmentOption {
        label
        value
      }
    }
  `,
  GET_MASTER_DATA: gql`
    query GET_MASTER_DATA {
      masterData
    }
  `,
  FETCH_EMPLOYEE_OPTIONS: gql`
    query FETCH_EMPLOYEE_OPTIONS {
      getEmployeeOptions {
        label
        value
      }
    }
  `,
};

const enhancer = compose(
  withAuthorize(),
  withTranslation({
    prefix: "pages.main.request.index",
  }),
  withHooks((props: any, hooks: any) => {
    const { useMemo, useQuery } = hooks;

    const { data: masterData } = useQuery(API.GET_MASTER_DATA, {
      fetchPolicy: "network-only",
    });

    const diseaseOption = useMemo(() => {
      const { dental, diseases } = masterData?.masterData || {};
      return [{ label: "ทั้งหมด", value: "all" }].concat(dental, diseases);
    }, [masterData]);

    const { data: departmentOptionsData } = useQuery(API.FETCH_OPTIONS, {
      fetchPolicy: "network-only",
    });

    const departmentOption = useMemo(() => {
      return [{ label: "ทั้งหมด", value: "all" }].concat(
        departmentOptionsData?.getDepartmentOption
      );
    }, [departmentOptionsData]);

    const claimStatusOptions = useMemo(() => {
      return [
        {
          label: "ทั้งหมด",
          value: "ALL",
        },
        {
          label: "รออนุมัติ",
          value: EnumClaimRequestStatus.WAITING,
        },
        {
          label: "อนุมัติ",
          value: EnumClaimRequestStatus.APPROVED,
        },
        {
          label: "ไม่อนุมัติ",
          value: EnumClaimRequestStatus.REJECTED,
        },
        {
          label: "ยกเลิก",
          value: EnumClaimRequestStatus.CANCELED,
        },
      ];
    }, []);

    const claimTypeOptions = useMemo(() => {
      return [
        {
          label: "ทั้งหมด",
          value: "all",
        },
        {
          label: "ค่ารักษาพยาบาล",
          value: "ค่ารักษาพยาบาล",
        },
        {
          label: "ดูแลบุตร",
          value: "ดูแลบุตร",
        },
        {
          label: "กรณีเสียชีวิต",
          value: "กรณีเสียชีวิต",
        },
      ];
    }, []);

    const claimNameOptions = useMemo(() => {
      return [
        {
          label: "ทั้งหมด",
          value: "all",
        },
        {
          label: "ค่ารักษาพยาบาล (ผู้ป่วยนอก - OPD)",
          value: "opd_medical",
        },
        {
          label: "ค่ารักษาพยาบาล (ผู้ป่วยใน - IPD)",
          value: "ipd_medical",
        },
        {
          label: "ทันตกรรม",
          value: "dental_fee",
        },
        {
          label: "ค่าช่วยเหลือการศึกษาบุตร",
          value: "child_education",
        },
        {
          label: "ค่าช่วยเหลือบุตร",
          value: "child_support",
        },
        {
          label: "ค่าช่วยเหลือจัดการงานศพ (3 เท่า)",
          value: "pass_away_support",
        },
        {
          label: "ค่าเจ้าภาพงานศพ",
          value: "pass_away_sponsor",
        },
        {
          label: "ค่าอุปกรณ์เคารพศพ",
          value: "pass_away_wreath",
        },
      ];
    }, []);

    const { data: employeesOptionsData } = useQuery(
      API.FETCH_EMPLOYEE_OPTIONS,
      {
        fetchPolicy: "network-only",
      }
    );
    const employeesOptions = useMemo(() => {
      if (!employeesOptionsData?.getEmployeeOptions) return [];
      return employeesOptionsData?.getEmployeeOptions;
    }, [employeesOptionsData]);

    return {
      diseaseOption,
      departmentOption,
      claimStatusOptions,
      claimTypeOptions,
      claimNameOptions,
      employeesOptions,
    };
  })
);

export default enhancer(ClaimRequestFilters);
