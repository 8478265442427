import { DataGrid, GridOverlay, GridToolbar } from "@material-ui/data-grid";
import { Box, Checkbox, Chip, IconButton, Typography } from "components";
import { format, parseISO } from "date-fns";
import { compose, defaultProps, withHooks } from "enhancers";
import { find, isEmpty, map, uniqueId } from "lodash";
import { formatPhoneNumber, toCurrency } from "utils/helper";

import { Tooltip, withStyles } from "@material-ui/core";

import { ReactComponent as EmailIcon } from "assets/icon/email.svg";
import { ReactComponent as FacebookIcon } from "assets/icon/facebook.svg";
import { ReactComponent as InstagramIcon } from "assets/icon/instagram.svg";
import { ReactComponent as LineIcon } from "assets/icon/line.svg";

import { ReactComponent as GreenDot } from "assets/icon/green_dot.svg";
import { ReactComponent as RedDot } from "assets/icon/red_dot.svg";

import { ReactComponent as WarningAmber } from "assets/icon/warning_amber.svg";

import { getCurrentLang } from "common/i18next";
import * as locales from "common/mui";
import React from "react";
import { AppColor } from "theme/app-color";
import { v4 as uuidv4 } from "uuid";
import { BOOKING_STATUS_TEXT } from "../../constants/index";
import { MaterialIcon } from "./MaterialIcon";
import T from "./T";
import { width } from "@material-ui/system";
// const StyledDataGrid = withStyles({
//   root: {
//     // "& .MuiDataGrid-viewport": {
//     //   maxHeight: "none !important",
//     // },
//     // "& .MuiDataGrid-renderingZone": {
//     //   maxHeight: "none !important",
//     // },
//     // "& .MuiDataGrid-cell": {
//     //   lineHeight: "unset !important",
//     //   maxHeight: "none !important",
//     //   whiteSpace: "normal",
//     // },
//     "& .MuiDataGrid-menuIcon": {
//       // display: "none !important",
//       display: "flex",
//       position: "absolute",
//       right: 0,
//       bottom: 0,
//       top: 0,
//       // visibility: "visible !important",
//     },
//     "& .MuiDataGrid-columnSeparator": {
//       visibility: "hidden",
//     },
//     "& .MuiDataGrid-iconButtonContainer": {
//       display: "none",
//     },
//     "& .MuiCheckbox-colorPrimary.Mui-disabled": {
//       "& .MuiIconButton-label": {
//         backgroundColor: AppColor["Background/Disable"],
//         borderRadius: "4px",
//         width: "20px",
//         height: "20px",
//       },
//     },
//     "& .MuiTablePagination-selectRoot": {
//       // แก้ pagination เพี้ยน
//       width: "60px !important",
//     },
//     "& .MuiDataGrid-row": {
//       cursor: "pointer",
//       transition: "background-color 0.1s ease-in-out", // ทำให้สีเปลี่ยนแบบนุ่มนวล
//     },
//     "& .MuiDataGrid-row:hover": {
//       backgroundColor: `${AppColor["Primary/Primary"]}20 !important`, // เปลี่ยนสี background ทั้งแถว
//     },
//     "& .MuiDataGrid-columnHeader": {
//       cursor: "default !important", // ปิด cursor pointer
//     },
//   },
// })(DataGrid);

const getStyledDataGrid = ({ hasRowClick }: { hasRowClick: boolean }) =>
  withStyles({
    root: {
      // "& .MuiDataGrid-viewport": {
      //   maxHeight: "none !important",
      // },
      // "& .MuiDataGrid-renderingZone": {
      //   maxHeight: "none !important",
      // },
      // "& .MuiDataGrid-cell": {
      //   lineHeight: "unset !important",
      //   maxHeight: "none !important",
      //   whiteSpace: "normal",
      // },
      "& .MuiDataGrid-menuIcon": {
        // display: "none !important",
        display: "flex",
        position: "absolute",
        right: 0,
        bottom: 0,
        top: 0,
        // visibility: "visible !important",
      },
      "& .MuiDataGrid-columnSeparator": {
        visibility: "hidden",
      },
      "& .MuiDataGrid-iconButtonContainer": {
        display: "none",
      },
      "& .MuiCheckbox-colorPrimary.Mui-disabled": {
        "& .MuiIconButton-label": {
          backgroundColor: AppColor["Background/Disable"],
          borderRadius: "4px",
          width: "20px",
          height: "20px",
        },
      },
      "& .MuiTablePagination-selectRoot": {
        // แก้ pagination เพี้ยน
        width: "60px !important",
      },
      "& .MuiDataGrid-row": {
        cursor: hasRowClick ? "pointer" : "default", // ถ้ามี onRowClick → cursor pointer
        transition: hasRowClick ? "background-color 0.1s ease-in-out" : "none",
      },
      "& .MuiDataGrid-row:hover": hasRowClick
        ? {
            backgroundColor: `${AppColor["Primary/Primary"]}20 !important`,
          }
        : {}, // ถ้าไม่มี onRowClick → ไม่มี hover effect
      "& .MuiDataGrid-columnHeader": {
        cursor: "default !important",
      },
    },
  })(DataGrid);

const BG_COLOR = {
  waiting: "#FF9800",
  confirmed: "#376FD0",
  paid: "#376FD0",
  complete: "#4CAF4F",
  canceled: "#F34336",
};

export const RENDER_CELLS = {
  available(row: any) {
    const { value } = row;

    return (
      <Box display="flex" alignItems="center" mx="auto">
        {value ? <GreenDot /> : <RedDot />}
      </Box>
    );
  },
  status(row: any) {
    const value = row.value as keyof typeof BOOKING_STATUS_TEXT;
    if (!value) {
      return <div></div>;
    }
    return (
      <Box whiteSpace="pre-wrap" p={1} pt={0}>
        <Chip
          size="small"
          label={BOOKING_STATUS_TEXT[value]}
          mr={1}
          mt={1}
          style={{
            fontSize: 12,
            fontWeight: 400,
            lineHeight: 14,
            backgroundColor: BG_COLOR[value],
            color: "white",
          }}
        />
      </Box>
    );
  },
  text(row: any) {
    const { value } = row;
    if (!value && value !== 0) {
      return <></>;
    }
    return (
      <Box whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
        {value}
      </Box>
    );
  },
  checkBox(row: any) {
    const { value } = row;
    if (!value) {
      return <></>;
    }
    const { statusSent, onClick, disabled } = value;
    return (
      <Box>
        <Checkbox
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation();
            onClick(row);
          }}
          checked={statusSent}
          disabled={disabled}
        ></Checkbox>
      </Box>
    );
  },
  isDefault(row: any) {
    const { value } = row;
    if (!value) {
      return <></>;
    }
    return (
      <Box mx="auto" mt="4px">
        <MaterialIcon
          name="Check"
          style={{ fontSize: "16px", color: AppColor["Secondary/Hover"] }}
        />
      </Box>
    );
  },
  caption(row: any) {
    const { value } = row;
    if (!value) {
      return <></>;
    }
    return (
      <Typography
        // whiteSpace="nowrap"
        // overflow="hidden"
        // textOverflow="ellipsis"
        variant="caption"
      >
        {value}
      </Typography>
    );
  },
  phoneNumber(row: any) {
    const { value } = row;
    if (!value) {
      return <></>;
    }
    return formatPhoneNumber(value);
  },
  date(row: any) {
    const { value } = row;
    if (!value) {
      return <></>;
    }
    return value;
  },
  currency(row: any) {
    const { value } = row;
    if (value === undefined || value === null || isNaN(value)) {
      return <></>;
    }
    return (
      <Box marginLeft="auto">
        {toCurrency(value, { minimumFractionDigits: 0 })}
      </Box>
    );
  },
  social(row: any) {
    const { value } = row;
    if (!value) {
      return <></>;
    }

    const [info, arr2] = value.split("{{STUDATA_SPLIT_STRING}}");
    const channel: keyof typeof SocialIcon = arr2 as keyof typeof SocialIcon;

    if (!info && !channel) {
      return <></>;
    }

    const SocialIcon = {
      facebook: FacebookIcon,
      ig: InstagramIcon,
      line: LineIcon,
      email: EmailIcon,
    };

    const Icon = SocialIcon[channel];

    return (
      <Box display="flex" alignItems="center">
        {Icon && <Icon style={{ marginRight: 8 }} />}
        {info}
      </Box>
    );
  },
  selector(row: any) {
    const { value } = row;
    if (!value) {
      return <></>;
    }
    return find(row.colDef.options, { value })?.label;
  },
  tags(row: any) {
    const { value } = row;
    if (!value) {
      return <></>;
    }
    const values = value.split(",");
    return (
      <Box whiteSpace="pre-wrap" p={1} pt={0}>
        {map(values, (v) => {
          return (
            <Chip
              size="small"
              label={v}
              mr={1}
              mt={1}
              style={{ fontSize: 12, lineHeight: 14 }}
            />
          );
        })}{" "}
      </Box>
    );
  },
  tag(row: any) {
    const { value } = row;
    if (!value) {
      return <></>;
    }

    const backgroundColor = ["ใช้งาน", "active"].includes(value)
      ? "#4CAF4F"
      : "#F34336";

    return (
      <Chip
        size="small"
        label={value}
        mr={1}
        style={{
          fontSize: 12,
          lineHeight: 14,
          color: "white",
          backgroundColor: backgroundColor,
        }}
      />
    );
  },
  notiStatus(row: any) {
    const { value } = row;
    if (!value && value !== false) {
      return <></>;
    }

    return (
      <Box display="flex" alignItems="center" mx="auto">
        {value ? <GreenDot /> : <RedDot />}
      </Box>
    );
  },
  warningStatus(row: any) {
    const { value } = row;
    if (!value && value !== false) {
      return <></>;
    }

    return (
      <Box display="flex" alignItems="center" mx="auto">
        {value ? <WarningAmber /> : null}
      </Box>
    );
  },
  actions(row: any) {
    const { value } = row;
    if (!value) {
      return <></>;
    }

    return value.map((action: any, index: number) => {
      const { Icon, onClick, can, deletedable, disabled, tooltip } = action;
      if (can === false) {
        return <></>;
      }
      let main = (
        <IconButton
          key={"IconButton_" + uuidv4()}
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation();
            onClick(row);
          }}
          disabled={disabled}
        >
          <Icon />
        </IconButton>
      );
      if (deletedable != null) {
        main = (
          <IconButton
            key={"IconButton_" + uuidv4()}
            style={{ cursor: deletedable ? "pointer" : "default" }}
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.stopPropagation();
              deletedable && onClick(row);
            }}
          >
            <Icon />
          </IconButton>
        );
      }

      if (tooltip)
        return (
          <Tooltip
            title={tooltip}
            arrow
            enterDelay={900}
            leaveDelay={0}
            enterNextDelay={700}
          >
            {main}
          </Tooltip>
        );
      return main;
    });
  },
};

const customValue = {
  dateOnly(row: any) {
    const { value } = row;
    if (!value) {
      return "-";
    }
    return format(parseISO(value), "dd/MM/yyyy");
  },
  date(row: any) {
    const { value } = row;
    if (!value) {
      return "-";
    }
    return value;
  },
  social(row: any) {
    const { value } = row;

    if (!value.channel || !value.info) {
      return null;
    }

    const { channel, info } = value;

    return `${info}{{STUDATA_SPLIT_STRING}}${channel}`;
  },
  tags(row: any) {
    const { value } = row;
    if (!value) {
      return null;
    }

    const labels = map(
      value,
      (v) => find(row.colDef.options, { value: v })?.label ?? ""
    ).join(",");

    return labels;
  },
};

//https://github.com/mui-org/material-ui-x/blob/HEAD/packages/grid/_modules_/grid/constants/localeTextConstants.ts
//https://material-ui.com/components/data-grid/localization/
// components={{
//   Toolbar: GridToolbar,
// }}

const enhancer = compose(
  defaultProps({
    style: {
      minHeight: 606,
    },
    density: "compact",
    autoHeight: true,
    autoPageSize: true,
    disableSelectionOnClick: true,
    rowsPerPageOptions: [25, 50, 100],
  }),
  withHooks((props: any, hooks: any) => {
    const {
      columns,
      onRowClickTo,
      onRowClick,
      autoDetectInitialFilterOnUrlQueryParams,
      isFilter = false,
      isShowHover = false,
      ...rest
    } = props;
    const { useMemo, useCallback, useState, useUrlParam } = hooks;
    const [pageSize, setPageSize] = useState(25);

    const customColumns = useMemo(() => {
      return map(columns, ({ type = "text", ...rest }) => {
        const children =
          RENDER_CELLS[type as keyof typeof RENDER_CELLS] || undefined;

        const renderCell = (props: any) => {
          if (type === "actions") {
            return RENDER_CELLS.actions(props);
          }

          if (isShowHover)
            return (
              <Tooltip
                title={props.value || ""}
                arrow
                enterDelay={1200}
                leaveDelay={0}
                enterNextDelay={1000}
              >
                <span>{children ? children(props) : props.value}</span>
              </Tooltip>
            );
          return <span>{children ? children(props) : props.value}</span>;
        };

        return {
          renderCell,
          valueGetter:
            customValue[type as keyof typeof customValue] || undefined,
          ...rest,
          filterable: false,
        };
      });
    }, [columns, isShowHover]);

    const hasOnRowClick = useMemo(() => onRowClickTo || onRowClick, [
      onRowClick,
      onRowClickTo,
    ]);

    const customOnRowClick = useCallback(
      (row: any) => {
        if (onRowClickTo) {
          return onRowClickTo(row.id).push();
        }
        if (onRowClick) {
          return onRowClick(row);
        }
      },
      [onRowClickTo, onRowClick]
    );

    const onPageSizeChange = useCallback(
      (newPage: number) => setPageSize(newPage),
      []
    );

    const urlParams = useUrlParam();
    const initialFilterModel = useMemo(() => {
      const items = map(urlParams, (value, key) => {
        return {
          id: uniqueId(),
          columnField: key,
          operatorValue: "contains",
          value: value,
        };
      });

      return isEmpty(items) ? undefined : { items };
    }, [urlParams]);
    const [filterModel, onFilterModelChange] = useState(initialFilterModel);
    const lang = getCurrentLang() as "th" | "en";
    const localeText = useMemo(() => locales[lang].filter, [lang]);

    const NoRowsOverlay = useCallback(() => {
      return (
        <GridOverlay
          style={{
            background: "none",
            height: "120px",
            padding: "0px",
          }}
        >
          <T variant="body1" color={AppColor["Text/Dark Grey"]}>
            {isFilter
              ? "client.components.Table.noDataOnFilter"
              : "client.components.Table.noData"}
          </T>
        </GridOverlay>
      );
    }, [isFilter]);

    const filterModelProps = useMemo(() => {
      if (props.useFilterModel)
        return {
          filterModel,
          onFilterModelChange,
        };
    }, [filterModel, props.useFilterModel]);

    return {
      pageSize,
      onPageSizeChange,
      ...rest,
      columns: customColumns,
      disableColumnSelector: true,
      onRowClick: hasOnRowClick ? customOnRowClick : undefined,
      ...filterModelProps,
      localeText,
      components: {
        Toolbar: props.includeToolbar ? GridToolbar : undefined,
        NoRowsOverlay,
      },
    };
  })
);

const CustomDataGrid = (props: any) => {
  const StyledDataGrid = getStyledDataGrid({ hasRowClick: !!props.onRowClick }); // เช็คว่ามี onRowClick หรือไม่

  return <StyledDataGrid {...props} />;
};

const CustomTable = enhancer(CustomDataGrid);

CustomTable.RENDER_CELLS = RENDER_CELLS;

export default CustomTable;
