import {
  Box,
  DatePicker,
  Field,
  FilterTableWithQueryParams,
  Grid,
  Select,
  TextField,
} from "components";

import { ENUM_CLAIM_REQUEST_DETAIL_STATUS } from "constants/enums/claim_request_detail_status.enum";
import {
  allOption,
  ClaimRequestMappingStatus,
} from "constants/objects/claim_request_mapping_status";
import { compose, withHooks, withTranslation } from "enhancers";
import paths from "routes/paths";
import { AppColor } from "theme/app-color";
import { createYupDateObject, gql, Yup } from "utils/helper";
import { waiting_requests_id } from "../PaymentCycleTable";

interface IClaimRequestForPaymentCycleFilterInput {
  startCreatedAt?: string;
  endCreatedAt?: string;
  startPaymentDate?: string;
  endPaymentDate?: string;
  status?: ENUM_CLAIM_REQUEST_DETAIL_STATUS | string;
  startReceiptDate?: string;
  endReceiptDate?: string;
  benefitTitle?: string;
  benefitType?: string;
  referenceId?: string;
  requester?: string;
  relation?: string;
  department?: string;
  receiptNo?: string;
  payRound?: string;
}

export const claimRequestPaymentCycleFilterInitialValues: IClaimRequestForPaymentCycleFilterInput = {
  startCreatedAt: undefined,
  endCreatedAt: undefined,
  startPaymentDate: undefined,
  endPaymentDate: undefined,
  status: allOption.value,
  department: allOption.value,
  requester: "",
  relation: "all",
  benefitType: allOption.value,
  benefitTitle: allOption.value,
  referenceId: "",
  receiptNo: "",
  startReceiptDate: undefined,
  endReceiptDate: undefined,
  payRound: "all",
};

const validationSchema = Yup.object().shape({
  startCreatedAt: createYupDateObject("startCreatedAt", "endCreatedAt", "LTE"),
  endCreatedAt: createYupDateObject("endCreatedAt", "startCreatedAt", "GTE"),
  startPaymentDate: createYupDateObject(
    "startPaymentDate",
    "endPaymentDate",
    "LTE"
  ),
  endPaymentDate: createYupDateObject(
    "endPaymentDate",
    "startPaymentDate",
    "GTE"
  ),
  startReceiptDate: createYupDateObject(
    "startReceiptDate",
    "endReceiptDate",
    "LTE"
  ),
  endReceiptDate: createYupDateObject(
    "endReceiptDate",
    "startReceiptDate",
    "GTE"
  ),
});

const PaymentCycleDetailFilters = ({
  t,
  isWaiting,
  departmentOptions,
  claimRequestTypesOptions,
  claimRequestTitleOptions,
  employeesOptions,
  relationShipOptions,
  payRoundOptions,
  canSelectDependent,

  handleRequesterChange,
}: any) => (
  <Box
    border={`1px solid ${AppColor["Text/Line"]}`}
    padding="22px 20px"
    borderRadius="8px"
    mt={6}
    mb={6}
  >
    <FilterTableWithQueryParams
      initialValues={claimRequestPaymentCycleFilterInitialValues}
      path={paths.paymentCycleDetailPath}
      validationSchema={validationSchema}
      onFieldsChange={handleRequesterChange}
    >
      <Box display="flex" flexDirection="column">
        <Grid container spacing={6} mt={6}>
          <Grid item xs={3}>
            <Field
              fast
              component={DatePicker}
              name="startCreatedAt"
              label={t(".startCreatedAt")}
              fullWidth
              clearable
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              fast
              component={DatePicker}
              name="endCreatedAt"
              label={t(".endCreatedAt")}
              fullWidth
              clearable
            />
          </Grid>

          <Grid item xs={3}>
            <Field
              fast
              component={DatePicker}
              name="startPaymentDate"
              label={t(".startPaymentDate")}
              fullWidth
              clearable
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              fast
              component={DatePicker}
              name="endPaymentDate"
              label={t(".endPaymentDate")}
              fullWidth
              clearable
            />
          </Grid>

          {isWaiting && (
            <Grid item xs={3}>
              <Field
                fast
                component={Select}
                name="status"
                label={t(".status")}
                options={[
                  allOption,
                  ...Object.values(ENUM_CLAIM_REQUEST_DETAIL_STATUS).map(
                    (value) => ({
                      label: ClaimRequestMappingStatus[value],
                      value: value,
                    })
                  ),
                ]}
                fullWidth
                disableClearable
              />
            </Grid>
          )}

          <Grid item xs={isWaiting ? 3 : 3}>
            <Field
              fast
              component={Select}
              name="department"
              label={t(".department")}
              options={departmentOptions}
              fullWidth
              disableClearable
            />
          </Grid>

          <Grid item xs={isWaiting ? 3 : 3}>
            <Field
              fast
              component={Select}
              name="requester"
              label={t(".requester")}
              fullWidth
              disableClearable
              options={employeesOptions}
            />
          </Grid>

          <Grid item xs={isWaiting ? 3 : 3}>
            <Field
              fast
              component={Select}
              name="relation"
              label="เบิกให้"
              fullWidth
              disableClearable
              options={relationShipOptions}
              disabled={!canSelectDependent}
            />
          </Grid>

          <Grid item xs={isWaiting ? 6 : 3}>
            <Field
              fast
              component={Select}
              name="payRound"
              label="ประเภทการจ่าย"
              fullWidth
              disableClearable
              options={payRoundOptions}
            />
          </Grid>

          <Grid item xs={6}>
            <Field
              component={Select}
              name="benefitType"
              label="ประเภทสวัสดิการ"
              options={claimRequestTypesOptions}
              fullWidth
              disableClearable
            />
          </Grid>

          <Grid item xs={6}>
            <Field
              component={Select}
              name="benefitTitle"
              label="ชื่อสวัสดิการ"
              options={claimRequestTitleOptions}
              fullWidth
              disableClearable
            />
          </Grid>

          <Grid item xs={3}>
            <Field
              component={TextField}
              name="referenceId"
              type="text"
              label="เลขอ้างอิง"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              component={TextField}
              name="receiptNo"
              type="text"
              label="เลขที่ใบเสร็จ"
              fullWidth
            />
          </Grid>

          <Grid item xs={3}>
            <Field
              fast={false}
              component={DatePicker}
              name="startReceiptDate"
              label="วันที่ตามใบเสร็จตั้งแต่"
              fullWidth
              // maxDate={values.endDate}
              clearable
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              fast={false}
              component={DatePicker}
              name="endReceiptDate"
              label="จนถึงวันที่"
              fullWidth
              // minDate={values.startDate}
              clearable
            />
          </Grid>
        </Grid>
      </Box>
    </FilterTableWithQueryParams>
  </Box>
);

const API = {
  FETCH_DEPARTMENT_OPTIONS: gql`
    query FETCH_DEPARTMENT_OPTIONS {
      getDepartmentOption {
        label
        value
      }
    }
  `,
  FETCH_REQUEST_TYPE_OPTIONS: gql`
    query FETCH_REQUEST_TYPE_OPTIONS {
      claimRequestTypeOptions {
        label
        value
      }
    }
  `,
  FETCH_REQUEST_TITLE_OPTIONS: gql`
    query FETCH_REQUEST_TITLE_OPTIONS {
      claimRequestTitleOptions {
        label
        value
      }
    }
  `,
  FETCH_EMPLOYEE_OPTIONS: gql`
    query FETCH_EMPLOYEE_OPTIONS {
      getBackofficeEmployeeOptions {
        label
        value
      }
    }
  `,
  GET_EMPLOYEE_DEPENDENTS: gql`
    query GET_EMPLOYEE_DEPENDENTS($employee: String) {
      getClaimRequestTo(input: { employee: $employee }) {
        label
        value
      }
    }
  `,
  GET_PAYROUND: gql`
    query GET_PAYROUND {
      getPayRoundOption {
        label
        value
      }
    }
  `,
};

const enhancer = compose(
  withTranslation({
    prefix: "pages.main.paymentCycles.detail.PaymentCycleDetailFilters",
  }),
  withHooks((props: any, hooks: any) => {
    const { t } = props;
    const {
      useMemo,
      useParams,
      useQuery,
      useState,
      useCallback,
      useLazyQuery,
    } = hooks;
    const { id } = useParams();

    const isWaiting = useMemo(() => id === waiting_requests_id, [id]);

    const { data: departmentOptionsData } = useQuery(
      API.FETCH_DEPARTMENT_OPTIONS,
      {
        fetchPolicy: "network-only",
      }
    );
    const departmentOptions = useMemo(() => {
      if (!departmentOptionsData?.getDepartmentOption) return [];
      return [allOption, ...departmentOptionsData?.getDepartmentOption];
    }, [departmentOptionsData]);

    const { data: claimRequestTypesData } = useQuery(
      API.FETCH_REQUEST_TYPE_OPTIONS,
      {
        fetchPolicy: "network-only",
      }
    );
    const claimRequestTypesOptions = useMemo(() => {
      if (!claimRequestTypesData?.claimRequestTypeOptions) return [];
      return [allOption, ...claimRequestTypesData?.claimRequestTypeOptions];
    }, [claimRequestTypesData]);

    const { data: claimRequestTitleData } = useQuery(
      API.FETCH_REQUEST_TITLE_OPTIONS,
      {
        fetchPolicy: "network-only",
      }
    );
    const claimRequestTitleOptions = useMemo(() => {
      if (!claimRequestTitleData?.claimRequestTitleOptions) return [];
      return [allOption, ...claimRequestTitleData?.claimRequestTitleOptions];
    }, [claimRequestTitleData]);

    const { data: employeesOptionsData } = useQuery(
      API.FETCH_EMPLOYEE_OPTIONS,
      {
        fetchPolicy: "network-only",
      }
    );
    const employeesOptions = useMemo(() => {
      if (!employeesOptionsData?.getBackofficeEmployeeOptions) return [];
      return employeesOptionsData?.getBackofficeEmployeeOptions;
    }, [employeesOptionsData]);

    const [employeeDependentOptions, setEmployeeDependentOptions] = useState(
      []
    );
    const [canSelectDependent, setCanSelectDependent] = useState(false);
    const [getUserDependents] = useLazyQuery(API.GET_EMPLOYEE_DEPENDENTS, {
      fetchPolicy: "network-only",
      onCompleted: (data: any) => {
        const dependents = data.getClaimRequestTo || [];
        console.log({ dependents });
        setEmployeeDependentOptions(dependents);
      },
    });
    const handleRequesterChange = useCallback(
      (values: any) => {
        if (values.requester) {
          getUserDependents({ variables: { employee: values.requester } });
          setCanSelectDependent(true);
        } else {
          setEmployeeDependentOptions([]);
          setCanSelectDependent(false);
        }
      },
      [getUserDependents]
    );
    const relationShipOptions = useMemo(() => {
      const defaultClaimRequestTo = "self";
      const owner = { label: "ตัวเอง", value: defaultClaimRequestTo };

      return [allOption, owner, ...employeeDependentOptions];
    }, [employeeDependentOptions]);

    const { data: payRoundOptionsData } = useQuery(API.GET_PAYROUND, {
      fetchPolicy: "network-only",
    });
    const payRoundOptions = useMemo(() => {
      return payRoundOptionsData?.getPayRoundOption || [];
    }, [payRoundOptionsData?.getPayRoundOption]);

    return {
      t,
      isWaiting,
      departmentOptions,
      claimRequestTypesOptions,
      claimRequestTitleOptions,
      employeesOptions,
      relationShipOptions,
      payRoundOptions,
      canSelectDependent,

      handleRequesterChange,
    };
  })
);

export default enhancer(PaymentCycleDetailFilters);
