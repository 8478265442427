import { ReactComponent as AddIcon } from "assets/icon/add-icon.svg";
import { ReactComponent as downloadIcon } from "assets/icon/download-icon.svg";
import { ReactComponent as FilterIcon } from "assets/icon/filter_outline.svg";
import { ReactComponent as Trash } from "assets/icon/red_trash.svg";
import {
  Box,
  Button,
  DatePicker,
  Field,
  Form,
  Grid,
  Modal,
  Notification,
  Table,
  Typography,
} from "components";
import Select from "components/common/Select";
import { PERMISSIONS } from "constants/enums/permissions";
import { ReportState } from "constants/enums/report-state";
import dayjs from "dayjs";
import {
  compose,
  withAuthorize,
  withFormik,
  withHooks,
  withStores,
  withTranslation,
} from "enhancers";
import { PageContent } from "layouts";
import appStore from "stores/appStore";
import styled from "styled-components";
import { AppColor } from "theme/app-color";
import { forceDownload, gql, homePath } from "utils/helper";
import SummaryReportModal from "./ SummaryReportModal";
import ReportModal from "./ReportModal";
import ReportFilter from "./ReportFilter";

const ReportComponent = (props: any) => (
  <PageContent
    title={props.title}
    breadcrumbs={props.breadcrumbs}
    pageActions={[
      {
        children: props.t(".report"),
        onClick: () => props.handleOpenReport(),
        variant: "outlined",
        permittedRoles: props.ReportPermission,
      },
      {
        children: props.t(".reportSummary"),
        startIcon: <AddIcon />,
        onClick: () => props.handleOpenSummaryReport(),
        variant: "contained",
        permittedRoles: props.summaryReportPermission,
      },
    ]}
    filter={<ReportFilter onSubmitFilter={props.onSubmitFilter} />}
  >
    <ReportModal
      isOpen={props.openReportModal}
      handleCloseModal={() => {
        props.setOpenReportModal(false);
      }}
      fetchData={props.fetchData}
    />
    <SummaryReportModal
      isOpen={props.openSummaryReportModal}
      handleCloseModal={() => {
        props.setOpenSummaryReportModal(false);
      }}
      fetchData={props.fetchData}
    />
    <Box width="100%" mb={-6}>
      <Typography variant="h4">{props.title}</Typography>
      <Table
        columns={props.columns}
        rows={props.tableData}
        loading={props.loading}
        autoHeight
      ></Table>
    </Box>
  </PageContent>
);

export const API = {
  FETCH_REPORTS: gql`
    query FETCH_REPORTS($filters: JSON) {
      reports(input: { filters: $filters }) {
        id
        reportName
        reportType
        state
        startDate
        endDate
        createdAt
        excelFileUrl
      }
    }
  `,
  DELETE_REPORTS: gql`
    mutation DELETE_REPORTS($id: String) {
      deleteReport(input: { id: $id })
    }
  `,
};

const enhancer = compose(
  withAuthorize(),
  withStores((stores: any) => ({
    currentUser: stores.appStore.currentUser,
  })),
  withTranslation({
    prefix: "pages.main.reports.index",
  }),
  withHooks((props: any, hooks: any) => {
    const {
      useQuery,
      useMemo,
      useMutation,
      useEffect,
      useCallback,
      useState,
    } = hooks;
    const { t, hasPermission, currentUser } = props;
    const currentPermissions = currentUser?.role?.permissions;
    const [openReportModal, setOpenReportModal] = useState(false);
    const [openSummaryReportModal, setOpenSummaryReportModal] = useState(false);
    const { loading, data, error, refetch } = useQuery(API.FETCH_REPORTS, {
      fetchPolicy: "network-only",
    });
    const [deleteReport] = useMutation(API.DELETE_REPORTS, {
      onCompleted: () => {
        Notification.success(t(".remove"));
        refetch();
      },
    });

    const handleOpenReport = useCallback(() => {
      setOpenReportModal(true);
    });
    const handleOpenSummaryReport = useCallback(() => {
      setOpenSummaryReportModal(true);
    });

    const fetchData = useCallback(
      async (changeValues?: any) => {
        if (currentUser) {
          if (changeValues)
            await refetch({
              filters: { ...changeValues, userId: currentUser?.id },
            });
          else refetch();
        }
      },
      [currentUser, refetch]
    );

    const hasEditPermission = useMemo(
      () => hasPermission([PERMISSIONS.REPORT_MANAGEMENT_EDIT]),
      [hasPermission]
    );

    //poling
    useEffect(() => {
      let count = 1;
      const intervalID = setInterval(() => {
        console.log(count++, "polling");
        // Check if all reports are complete
        const allReportsCompleteOrFail = data?.reports.every(
          (items: any) => items.state === "complete" || items.state === "failed"
        );

        // If all reports are complete, clear the interval
        if (allReportsCompleteOrFail) {
          clearInterval(intervalID);
          return;
        }

        // If not all reports are complete, continue refetching
        // refetch({
        //   filters: { ...values, userId: appStore.state.currentUser.id },
        // });
        fetchData();
      }, 5000);

      // Cleanup function
      return () => {
        clearInterval(intervalID);
      };
    }, [data?.reports, fetchData]);

    const title = t(".reportMenu");
    const breadcrumbs = useMemo(() => {
      return [
        { path: homePath(), label: t(".home") },
        { path: null, label: title },
      ];
    }, [title, t]);

    const getReportStateDisplay = useCallback((state: string) => {
      switch (state) {
        case "complete":
          return "สำเร็จ";
        case "generating":
          return "กำลังสร้าง";
        case "failed":
          return "ล้มเหลว";
        default:
          return state;
      }
    }, []);

    const columns = useMemo(
      () => [
        {
          width: 200,
          field: "reportName",
          headerName: t(".columns.reportName") || "",
        },
        {
          width: 300,
          field: "reportType",
          headerName: t(".columns.reportType") || "",
        },
        {
          width: 150,
          field: "startDate",
          headerName: t(".columns.startDate") || "",
          type: "string",
          renderCell: (startDate: any) => {
            return dayjs(startDate.value)
              .locale("th")
              .add(543, "year")
              .format("DD/MM/YYYY");
          },
        },
        {
          width: 150,
          field: "endDate",
          headerName: t(".columns.endDate") || "",
          type: "string",
          renderCell: (endDate: any) => {
            return dayjs(endDate.value)
              .locale("th")
              .add(543, "year")
              .format("DD/MM/YYYY");
          },
        },
        {
          width: 100,
          field: "state",
          headerName: t(".columns.state") || "",
          type: "string",
          renderCell: (state: any) => {
            return getReportStateDisplay(state.value);
          },
        },
        {
          width: 150,
          field: "createdAt",
          headerName: t(".columns.createdAt") || "",
          type: "date",
          renderCell: (createdAt: any) => {
            return dayjs(createdAt.row.createdAt)
              .locale("th")
              .add(543, "year")
              .format("DD/MM/YYYY, HH:mm");
          },
        },
        {
          width: 100,
          headerName: "Action",
          filterable: false,
          sortable: false,
          field: "actions",
          type: "actions",
        },
      ],
      [getReportStateDisplay, t]
    );
    const downloadFile = useCallback(
      (excelFileUrl: string, reportName: string) => async () => {
        if (reportName && excelFileUrl) {
          const loadFile = async () => {
            try {
              const blob = await fetch(`${excelFileUrl}`).then((r) => r.blob());
              const file = new File([blob], reportName, {
                type:
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              });
              if (file) {
                const url = URL.createObjectURL(file);
                forceDownload(url, reportName);
              }
              Notification.success(t(".downloadSuccess"));
            } catch (e) {
              Notification.error(e);
            }
          };

          loadFile();
        } else {
          alert("Coming Soon!");
        }
      },
      [t]
    );
    const deleteFile = useCallback(
      (id: string, name: string) => () => {
        Modal.open({
          title: t(".removeTitle"),
          children: (
            <Box
              flex={1}
              mb={5}
              display="flex"
              flexDirection="row"
              style={{ gap: "4px" }}
            >
              <Typography variant="body1">{"รายงาน"}</Typography>
              <Typography variant="body2">{name}</Typography>
              <Typography variant="body1">
                {" จะถูกลบและไม่สามารถกู้คืนได้"}
              </Typography>
            </Box>
          ),
          cancelButtonLabel: t(".removeButtonCancel"),
          okButtonLabel: t(".removeButtonOK"),
          okButtonVariant: "contained",
          cancelButtonVariant: "outlined",
          onOk: async ({ close }: any) => {
            await deleteReport({ variables: { id } });
            await fetchData();
            close();
          },
        });
      },
      [deleteReport, fetchData, t]
    );

    const tableData = useMemo(() => {
      if (loading || error) {
        return [];
      }
      return data?.reports.map((report: any) => {
        const {
          id,
          reportName,
          reportType,
          state,
          startDate,
          endDate,
          createdAt,
          excelFileUrl,
        } = report;
        return {
          id: id,
          reportName,
          reportType,
          state,
          startDate,
          endDate,
          createdAt,
          actions:
            !hasEditPermission || state === ReportState.generating
              ? []
              : [
                  {
                    Icon: downloadIcon,
                    onClick: downloadFile(excelFileUrl, reportName),
                    disabled: !(state === ReportState.complete),
                    tooltip: "Download",
                  },
                  {
                    Icon: Trash,
                    onClick: deleteFile(id, reportName),
                    disabled: !(
                      state === ReportState.complete ||
                      state === ReportState.failed
                    ),
                  },
                ],
        };
      });
    }, [
      loading,
      error,
      data?.reports,
      hasEditPermission,
      downloadFile,
      deleteFile,
    ]);

    const reportMockRaw = useMemo(() => [
      {
        label: "รายงานการเบิกค่ารักษาพยาบาล (รายพนักงาน)",
        value: "MEDICAL_REIMBURSEMENT_PER_EMPLOYEE",
      },
      {
        label: "รายงานการเบิกค่ารักษาพยาบาลตามฝ่าย/สังกัด",
        value: "MEDICAL_REIMBURSEMENT_DEPARTMENT",
      },
      {
        label: "รายงานเงินช่วยเหลือบุตร (รายพนักงาน)",
        value: "SUPPORT_CHILD_PER_EMPLOYEE",
      },
      {
        label:
          "รายงานเงินช่วยเหลือบุตรที่ยังไม่เกิน 18 ปีบริบูรณ์ (รายพนักงาน)",
        value: "SUPPORT_CHILD_UNDER_18_PER_EMPLOYEE",
      },
      {
        label: "รายงานประวัติการเบิกเงินช่วยเหลือบุตร",
        value: "SUPPORT_CHILD_HISTORY_DISBURSEMENT",
      },
      {
        label: "รายงานการเบิกค่าช่วยเหลือการศึกษาบุตร (รายพนักงาน)",
        value: "EDUCATION_CHILD_PER_EMPLOYEE",
      },
      {
        label: "รายงานการเบิกค่าช่วยเหลือจัดการงานศพ (3 เท่า)",
        value: "COMPENSATION_FUNERAL_DISBURSEMENT_SUPPORT",
      },
      {
        label: "รายงานการเบิกค่าเจ้าภาพงานศพ",
        value: "COMPENSATION_FUNERAL_DISBURSEMENT",
      },
      {
        label: "รายงานการเบิกค่าอุปกรณ์เคารพศพ",
        value: "COMPENSATION_FUNERAL_DISBURSEMENT_EQUIPMENT",
      },
      {
        label: "สรุปการเบิกค่ารักษาพยาบาลตามฝ่าย/สังกัด",
        value: "SUMMARY_MEDICAL_REIMBURSEMENT_DEPARTMENT",
      },
      {
        label: "สรุปการเบิกค่าเล่าเรียนบุตรตามฝ่าย/สังกัด",
        value: "SUMMARY_CHILD_EDUCATION_REIMBURSEMENT_DEPARTMENT",
      },
      {
        label: "สรุปการเบิกเงินช่วยเหลือบุตรตามฝ่าย/สังกัด",
        value: "SUMMARY_CHILD_SUPPORT_REIMBURSEMENT_DEPARTMENT",
      },
      {
        label: "สรุปรายการเบิกทั้งหมด รายครั้ง",
        value: "SUMMARY_ALL_EACH_TIMES",
      },
    ]);
    const reportMockRaw2 = useMemo(() => [
      {
        label: "รายงานเงินช่วยเหลือบุตร (เพิ่มขึ้น) ตามฝ่าย/สังกัด",
        value: "AFFECT_CHILD_SUPPORT_INCREASE",
      },
      {
        label: "รายงานเงินช่วยเหลือบุตร (ลดลง) ตามฝ่าย/สังกัด",
        value: "AFFECT_CHILD_SUPPORT_REDUCE",
      },
    ]);

    const summaryReportPermission = useMemo(() => {
      let permissionsArray = [];
      if (currentPermissions.includes("EXPORT_ALL_REPORT")) {
        permissionsArray = reportMockRaw.map((opt: any) => opt.value);
      } else {
        permissionsArray = reportMockRaw
          .filter((opt: any) => currentPermissions.includes(opt.value))
          .map((opt: any) => opt.value);
      }
      permissionsArray.push(
        permissionsArray.length !== 0
          ? "REPORT_MANAGEMENT_EDIT"
          : "NONE_PERMISSION"
      );
      return permissionsArray;
    }, [currentPermissions]);

    const ReportPermission = useMemo(() => {
      let permissionsArray = [];
      if (currentPermissions.includes("EXPORT_ALL_REPORT")) {
        permissionsArray = reportMockRaw2.map((opt: any) => opt.value);
      } else {
        permissionsArray = reportMockRaw2
          .filter((opt: any) => currentPermissions.includes(opt.value))
          .map((opt: any) => opt.value);
      }
      permissionsArray.push(
        permissionsArray.length !== 0
          ? "REPORT_MANAGEMENT_EDIT"
          : "NONE_PERMISSION"
      );
      return permissionsArray;
    }, [currentPermissions]);

    const onSubmitFilter = useCallback(
      async (params: any) => {
        console.log(params);
        await fetchData(params);
      },
      [fetchData]
    );

    return {
      title,
      breadcrumbs,
      tableData,
      columns,
      loading,
      openReportModal,
      openSummaryReportModal,
      handleOpenReport,
      handleOpenSummaryReport,
      setOpenReportModal,
      setOpenSummaryReportModal,
      fetchData,
      summaryReportPermission,
      ReportPermission,

      onSubmitFilter,
    };
  })
);

export const ReportPage = enhancer(ReportComponent);
